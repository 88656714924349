import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main'),
  },
  {
    path: '/calc',
    name: 'Calc',
    component: () => import('../views/Calc'),
  },
  {
    path: '/total',
    name: 'Total',
    component: () => import(/* webpackChunkName: "about" */ '../views/Total'),
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('../views/Articles'),
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
