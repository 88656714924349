export default {
  sections: [
    {
      head: 'Жилье',
      component: 'house',
      backgroundSvg: 'finalapartament',
    },
    {
      head: 'Личный транспорт',
      component: 'transport',
      backgroundSvg: 'parkingfinal',
    },
    {
      head: 'Логистика',
      component: 'logistics',
      backgroundSvg: 'cityfinale',
    },
    {
      head: 'Пища',
      component: 'food',
      backgroundSvg: 'foodbackground',
    },
    {
      head: 'Путешествия',
      component: 'travel',
      background: 'travel-svg',
    },
  ],
  houseModel: null,
  logisticsModel: null,
  foodModel: null,
  travelModel: null,
  transportModel: null,
  total: null,
  group: 0,
};
