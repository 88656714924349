import config from '../config';

export default {
  calc({ state, commit }) {
    const models = [
      'houseModel',
      'foodModel',
      'travelModel',
      'logisticsModel',
      'transportModel',
    ];
    const valuesObj = {};
    models.forEach((elem) => { valuesObj[elem] = state[elem].getValues(); });
    config.api.post('/calc', { data: valuesObj }).then((res) => {
      commit('SET_TOTAL', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
};
