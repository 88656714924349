import axios from 'axios';

const baseURL = /localhost/.test(window.origin) ? 'http://localhost:3000/tvoysled/api/v1' : `${window.origin}/tvoysled/api/v1`;
const instance = axios.create({
  baseURL,
  withCredentials: false,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
});

export default {
  api: instance,
};
