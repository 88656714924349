import Vue from 'vue';
import router from '../router';

export default {
  SET_MODEL_HOUSE(state, model) {
    Vue.set(state, 'houseModel', model);
  },
  SET_MODEL_TRANSPORT(state, model) {
    Vue.set(state, 'transportModel', model);
  },
  SET_MODEL_LOGISTICS(state, model) {
    Vue.set(state, 'logisticsModel', model);
  },
  SET_MODEL_FOOD(state, model) {
    Vue.set(state, 'foodModel', model);
  },
  SET_MODEL_TRAVEL(state, model) {
    Vue.set(state, 'travelModel', model);
  },
  SET_TOTAL(state, data) {
    Vue.set(state, 'total', data);
    router.push('/total');
  },
  SET_GROUP(state, value) {
    state.group = value;
  },
};
