import Vue from 'vue';
import vuescroll from 'vuescroll';
import VeeValidate from 'vee-validate';
import VueMask from 'v-mask';
import VueApexCharts from 'vue-apexcharts';
import App from './App';
import vuetify from './plugins/vuetify';
import store from './store';
import BaseIcon from './components/graphics/BaseIcon';
import router from './router';
import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.use(vuescroll);
Vue.use(VueMask);
Vue.use(VeeValidate);
Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);
Vue.component('BaseIcon', BaseIcon);

router.beforeEach((to, from, next) => {
  const { name } = to;
  switch (name) {
    case 'Main':
      store.commit('SET_GROUP', 0);
      break;
    case 'Calc':
    case 'Total':
      store.commit('SET_GROUP', 1);
      break;
    case 'Articles':
      store.commit('SET_GROUP', 2);
      break;
    case 'Personal':
      store.commit('SET_GROUP', 3);
      break;
    default:
      break;
  }
  next();
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
